import React, { useState } from "react";
import UstMenu from "../layouts/UstMenu";

const HaberEkle = () => {
  const [baslik, setBaslik] = useState("");
  const [aciklama, setAciklama] = useState("");
  const [haberKaynagi, setHaberKaynagi] = useState("");
  const [haberKaynakUrl, setHaberKaynagiUrl] = useState("");
  const [resim, setHaberResim] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault(); // Formun default davranışını engelle

    console.log(
      "formData",
      resim,
      baslik,
      aciklama,
      haberKaynagi,
      haberKaynakUrl,
    ); // Debugging için formData'yı konsola yazdırın

    try {
      const response = await fetch("http://localhost:3030/api/news", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          resim,
          baslik,
          aciklama,
          haberKaynagi,
          haberKaynakUrl,
        }), // JSON.stringify ile bir nesne oluşturulmalı
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      alert("Haber başarıyla eklendi!");
      console.log(result);
    } catch (error) {
      console.error("Error:", error);
      alert("Haber eklenirken bir hata oluştu.");
    }
  };

  return (
    <div>
      <UstMenu />
      <div className="container col-12 col-md-6">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="resim" className="form-label">
              Haber Resim
            </label>
            <input
              type="text"
              className="form-control"
              id="resim"
              aria-describedby="resimHelp"
              value={resim}
              onChange={(e) => setHaberResim(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="baslik" className="form-label">
              Başlık
            </label>
            <input
              type="text"
              className="form-control"
              aria-describedby="baslikHelp"
              id="baslik"
              value={baslik}
              onChange={(e) => setBaslik(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="aciklama" className="form-label">
              Açıklama
            </label>
            <textarea
              rows="3"
              type="text"
              className="form-control"
              id="aciklama"
              value={aciklama}
              onChange={(e) => setAciklama(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="haberKaynagi" className="form-label">
              Haber Kaynağı
            </label>
            <input
              type="text"
              className="form-control"
              id="haberKaynagi"
              value={haberKaynagi}
              onChange={(e) => setHaberKaynagi(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="haberKaynakUrl" className="form-label">
              Haber Kaynağı URL
            </label>
            <input
              type="text"
              className="form-control"
              id="haberKaynakUrl"
              value={haberKaynakUrl}
              onChange={(e) => setHaberKaynagiUrl(e.target.value)}
            />
          </div>
          <button
            type="submit"
            style={{ backgroundColor: "#E7371F", borderColor: "#E7371F" }}
            className="btn btn-primary"
          >
            Gönder
          </button>
        </form>
      </div>
    </div>
  );
};

export default HaberEkle;
