import React from 'react'
import logo from '../assets/img/logo.png';

const UstMenu = () => {
  return (
    <div className='menu-arka-renk'>
       <nav className="container navbar navbar-expand-lg menu-arka">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src={logo} alt="DovizUp Logo" width="150" height="50" className="d-inline-block align-top" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active menu-a" aria-current="page" href="/HaberEkle">Haber Ekle</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active menu-a" aria-current="page" href="/HaberList">Haber List</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active menu-a" aria-current="page" href="/StoryEkle">Story Ekle</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active menu-a" aria-current="page" href="/StoryList">Story List</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active menu-a" aria-current="page" href="/SorunBildir">Sorun Bildirimleri</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active menu-a" aria-current="page" href="/DovizTakip">Döviz Kuru Takip</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active menu-a" aria-current="page" href="/Blog">Blog</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active menu-a" aria-current="page" href="/Duyur">Duyuru Bildirim</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
    </div>
  )
}

export default UstMenu
