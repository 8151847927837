import React, { useState, useEffect } from "react";
import logo from "../assets/img/logo.png";
import UstMenu from "../layouts/UstMenu";
import Update from "../assets/svg/Update";
import Delete from "../assets/svg/Delete";

const HaberList = () => {
  const [newsList, setNewsList] = useState([]);

  // Component yüklendiğinde veri getirme
  useEffect(() => {
    fetchNews();
  }, []);

  // Veriyi getiren fonksiyon
  const fetchNews = async () => {
    try {
      const response = await fetch("http://localhost:3030/api/news");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setNewsList(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <UstMenu />
      <nav className="container">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Resim</th>
              <th scope="col">Başlık</th>
              <th scope="col">Açıklama</th>
              <th scope="col">Haber Kaynağı</th>
              <th scope="col">Haber Kaynağı Url</th>
              <th scope="col">Güncelle</th>
              <th scope="col">Sil</th>
            </tr>
          </thead>
          <tbody>
            {newsList.map((newsItem, index) => (
              <tr key={newsItem.id || index}>
                <th scope="row">{index + 1}</th>
                <td>
                  <img
                    src={newsItem.resim || logo}
                    height={60}
                    width={180}
                    alt="news"
                  />
                </td>
                <td>{newsItem.baslik}</td>
                <td>{newsItem.aciklama}</td>
                <td>{newsItem.haberKaynagi}</td>
                <td>
                  <a href={newsItem.haberKaynakUrl} target="_blank">
                    {newsItem.haberKaynakUrl}
                  </a>
                </td>
                <td>
                  <a href="#">
                    <Update height={20} width={20} fill={"#0000ff"} />
                  </a>
                </td>
                <td>
                  <a href="#">
                    <Delete height={20} width={20} fill={"#ff0000"} />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </nav>
    </div>
  );
};

export default HaberList;
