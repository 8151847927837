import React, { useEffect, useState } from "react";

import logo from "../assets/img/logo.png";
import UstMenu from "../layouts/UstMenu";
import Update from "../assets/svg/Update";
import Delete from "../assets/svg/Delete";

const DovizTakip = () => {
  const [currencyTracking, setCurrencyTracking] = useState([]);

  // Component yüklendiğinde veri getirme
  useEffect(() => {
    fetchNews();
  }, []);

  // Veriyi getiren fonksiyon
  const fetchNews = async () => {
    try {
      const response = await fetch("http://localhost:3030/api/fetch-data");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const responseData = await response.json();
      console.log("API Response Data:", responseData);

      // responseData.data, gerçek veri dizisini içeriyor
      if (responseData.success && Array.isArray(responseData.data)) {
        processCurrencyData(responseData.data);
      } else {
        console.error("Data is not an array:", responseData);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Verileri gruplandıran fonksiyon
  const processCurrencyData = (data) => {
    const groupedData = data.reduce((acc, item) => {
      if (!acc[item.cname]) {
        acc[item.cname] = {
          cname: item.cname,
          imgSrc: item.imgSrc,
          buyValue: "",
          sellValue: "",
        };
      }

      // Kur alış ve satış değerlerini ayırın
      if (!acc[item.cname].buyValue) {
        acc[item.cname].buyValue = item.textBold.slice(0, 7);
        acc[item.cname].sellValue = item.textBold.slice(9, 17);
      } else {
        acc[item.cname].buyValue = item.textBold.slice(0, 7);
        acc[item.cname].sellValue = item.textBold.slice(9, 17);
      }

      return acc;
    }, {});
    setCurrencyTracking(Object.values(groupedData));
  };

  console.log("currencyTracking", currencyTracking);
  return (
    <div>
      <UstMenu />
      <nav className="container">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Kur İsmi</th>
              <th scope="col">Kur Bayrağı</th>
              <th
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                scope="col"
              >
                Kur Alış Değeri
              </th>
              <th
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                    scope="col"
              >
                Kur Satış Değeri
              </th>
            </tr>
          </thead>
          <tbody>
            {currencyTracking.map((currency, index) => (
              <tr key={currency.id || index}>
                <th scope="row">{index + 1}</th>
                <td>{currency.cname}</td>
                <td>
                  <img
                    height={40}
                    width={80}
                    style={{borderRadius:4}}
                    src={currency.imgSrc}
                    alt="currency flag"
                  />
                </td>
                <td
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {currency.buyValue}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {currency.sellValue}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </nav>
    </div>
  );
};

export default DovizTakip;
