import React, { useState } from "react";
import UstMenu from "../layouts/UstMenu";

const BlogGizlilikPolitikasi = () => {
  return (
    <div>
      <UstMenu />
      <div className="container">
        <h1
          style={{
            textAlign: "center",
          }}
        >
          Mobil Uygulama Gizlilik Bildirimi
        </h1>
        <p
          style={{
            textAlign: "left",
          }}
        >
          Sürüm, 10 Nisan 2019 Tüm Kullanıcılarımızın gizliliği bizim için çok
          önemlidir. Bir Uygulama Kullanıcısı olarak Hizmetimizi
          kullandığınızda, (Uygulama Yayıncısı olarak) Kişisel Verilerinizi
          işleriz. Bu Gizlilik Bildirimi, Kişisel Verilerinizi nasıl
          koruduğumuzu ve işleme koyduğumuzu açıklar. Dikkatlice okumanızı
          öneririz.
        </p>
        <div>
          <h3>1. Bu Gizlilik Bildiriminde ne belirtilmiştir?</h3>
          <p>
            Bu Gizlilik Bildirimindeki bölümler size aşağıdakiler hakkında bilgi
            verir: İşlenen Kişisel Verilerin türleri, Kişisel Verilerin işlenme
            amaçları, Kişisel Verilerin işlendiği yerler, Kişisel Verileri
            korumak için uygulanan güvenlik önlemleri, Üçüncü şahıslarla ilgili
            sorumluluk sınırları, Kişisel Verilerinizi görüntüleme, değiştirme
            ve silme, Bu Gizlilik Bildiriminde yapılan değişiklikler; Sorunuz
            veya açıklamalarınız varsa ne yapacaksınız?
          </p>
        </div>
        <div>
          <h3>2. İşlenen Kişisel Verilerin türleri</h3>
          <p>
            A. Hizmetimiz tarafından kullanılan Kişisel Veriler Hizmetimizi
            geliştirmek için Anonim Verileri kullanırız. Bu bilgiler, Hizmet
            aracılığıyla kamuya açıklanmayacaktır. B. Otomatik olarak
            oluşturulan bilgiler Çoğu diğer web siteleri ve çevrimiçi hizmetler
            gibi, Uygulamayı nasıl kullandığınızla ilgili olarak otomatik olarak
            üretilen bilgileri toplar ve işleriz. Toplanan bilgiler, ip
            adresinizi ve / veya benzersiz cihaz kimliğinizi içerir. Özellikle
            katılmayı seçerseniz, Uygulama, coğrafi konum bilgilerinizi
            toplayabilir. Her durumda, coğrafi konum bilginizin alınmasını mobil
            cihazınızın ayarlarından engelleyebilirsiniz. Sosyal ağ hesabınızdan
            bilgi erişimi ve toplama izni vermeyi özellikle tercih ederseniz,
            sosyal ağ hesabınızdaki temel kişisel bilgileriniz (adınız ve
            e-posta adresiniz gibi) ve ayrıca sosyal ağ kullanıcı kimliği (
            şifreniz değil ) ve Uygulama aracılığıyla paylaştığınız yayınlarla
            ilgili parametreler. Erişilebilen ve alınabilecek bilgileri kontrol
            etmek için hesabınızın gizlilik tercihlerini nasıl
            ayarlayabileceğiniz hakkında daha fazla bilgi için lütfen sosyal
            ağın gizlilik politikasına bakın. Bu, aynı zamanda, Uygulamayı nasıl
            kullandığınıza ilişkin otomatik olarak oluşturulmuş bilgilerle
            ilgili olarak gerçekleşir. Bu tür bilgiler, Uygulamanın nasıl
            kullanıldığını daha iyi anlamamıza ve bir kullanıcı olarak
            ihtiyacınıza uygun hizmet üretmemize yardımcı olur. Uygulama
            içindeki kullanıcı yetkilendirme özelliklerini etkinleştirirseniz,
            aşağıdaki ek bilgiler saklanır: İsim, E-posta, Telefon numarası
            (isteğe bağlı); Facebook, Twitter, Google+ veya LinkedIn gibi harici
            bir sağlayıcı tarafından gönderilen ek profil bilgileri. Uygulama
            sahibine, uygulamanın kullanımı hakkında bilgi vermek için aşağıdaki
            (bilinmeyen) bilgileri de topluyoruz: • Uygulamayı açtığınız an, •
            Uygulamanın içinde açtığınız ekranlar ve bu ekranlarda harcadığınız
            süre, • Uygulamadan ayrıldığınız an. Not: kullanıcı yetkilendirme
            özellikleri etkinleştirilmişse, giriş yaptığınızda anonim bilgileri
            sizin bilgilerinizle ilişkilendirebiliriz. Eğer uygulamada anlık
            bildirim reklamları aktifse, uygulama ile entegre çalışan BleshSDK
            hakkındaki bilgiler aşağıda sunulmaktadır. BleshSDK vasıtasıyla
            Kullanıcılar’ın bildirim izni durumu, bluetooth durum bilgisi, cihaz
            bilgileri, reklam tanıtıcısı (IDFA), konum, operatör, yüklü uygulama
            listesi bilgilerine erişilmekte olup sağlanan bu bilgilerin
            gizliliği için gerekli güvenlik önlemleri alınmaktadır.
            Kullanıcı’nın, kendisine iletilen kampanya görüntüsünü fotoğraf
            albümüne kaydetmek istemesi durumunda, Kullanıcı’nın fotoğraf albümü
            ve kamerasına erişilmekte ve ekran görüntüsü fotoğraf albümüne
            kaydedilmektedir. Bilgiler yalnızca Kullanıcı’nın izin verdiği
            ölçüde Uygulama üzerinden anlık bildirimler gönderme amacı
            kapsamında, güvenli şekilde ve sadece gereken süre boyunca
            kullanılmaktadır. Bilgiler yalnızca söz konusu amaçların
            gerçekleştirilmesi için üçüncü partiler ile paylaşılmakta olup bu
            kapsam dışında bilgilerin yayınlanması ve ifşa edilmesine izin
            verilmemektedir. C. Belirli Bilgiler Size sadakat kartı, haber
            bültenleri, reklamcılık gibi bazı etkinliklerle başvurmanız
            istenebilir; bu durumda bazı kişisel bilgiler istenir. Bu bilgiler,
            hizmetimizin (üçüncü şahıslar dahil) veritabanında saklanır ve
            bizimle paylaşılacaktır. Uygulamadaki fotoğraflar da dahil olmak
            üzere veri yüklediğinizde, bu paylaşılacak ve uygulamanın diğer tüm
            kullanıcıları tarafından görülebilecektir.
          </p>
        </div>
        <div>
          <h3>3. Kişisel Verilerin işlenme amaçları</h3>
          <p>
            A. Amaçlar Taraflar Kişisel Verileri aşağıdaki amaçlarla
            işlemektedir: Hizmeti kullanabilmenizi sağlamak için, Hizmetimizle
            ilgili bilgileri güncel tutmak için, Hizmetin iyileştirilmesi
            ve/veya özelleştirilmesi için, Sizi tanımlamak ve/veya Hizmeti
            özelleştirmek için, Sizi tanımak ve dolandırıcılığı önlemek için,
            Destek sağlamak için, Kişisel Verilerinizi, bizden bunu talep
            ettiğiniz takdirde veya yasal olarak yükümlü olduğumuz durumlarda,
            üçüncü şahıslara iletmek. B. Kişisel Verilerin üçüncü şahıslara
            iletilmesi Sizden önceden izin alınmaksızın Kişisel Verilerinizi
            üçüncü şahıslara satmamaktayız, satmıyor veya kiralamıyoruz. Bununla
            birlikte, Ücretli Hizmetlerle ilgili ödeme sağlayıcıları
            aracılığıyla ödeme yapmak gibi Kişisel Verilerinizi size sağlama
            veya talimatları yerine getirmeniz açısından şart olduğu durumlarda,
            Kişisel Verilerinizi üçüncü taraflara sağlayabiliriz. Uygun olduğunu
            düşündüğümüz şekilde Hizmet'in bu tür amaçlar için üçüncü taraflara
            kullanımı hakkında "toplu anonim veriler" sağlayabiliriz. "Toplam
            anonim veriler", size geri gönderilemeyen ve bu nedenle Kişisel
            Veriler olarak sayılmayan verilerdir. Örneğin, Kullanıcıların
            Hizmeti nasıl kullandığını daha iyi anlamak için toplu anonim
            verileri kullanabiliriz. Üçüncü bir tarafa geçtiğimiz veya bir
            üçüncü tarafla birleştiğimiz veya bir yeniden yapılandırmaya tabi
            olduğumuz durumda, Kişisel Verileriniz de üçüncü kişilere
            açıklanabilir ve / veya bu üçüncü kişiye devredilebilir. Bu üçüncü
            taraf, Kişisel Verileri ve bize sağladığınız diğer bilgileri
            kullanmaya devam etme hakkına sahip olacak. Kişisel Verilerinizi,
            iyi niyetle, bir mahkeme kararı, halen devam eden adli işlem, cezai
            veya sivil mahkeme celbi veya kolluk kuvveti makamları tarafından
            verilen diğer yasal işlem veya taleplere uymanız gerektiğine
            inandığımız yerlerde ifşa edebiliriz veya egzersiz yapabiliriz Yasal
            hakları veya yasal iddialara karşı savunmak.
          </p>
        </div>
        <div>
          <h3>4. Kişisel Verilerin işlendiği yerler</h3>
          <p>
            Hizmet kapsamında işlenen verilerin tutulduğu veri merkezleri
            değişik bölgelerde bulunmaktadır., Türkiye, İrlanda, Hollanda,
            Amerika Birleşik Devletleri bölgelerinde yer alan barındırma
            hizmetleri kullanılarak sağlanmaktadır. Bununla birlikte, işlenen
            Kişisel Veriler, bu ülkeler ve bölgeler dışında bir ülkede bulunan
            muhafaza sunucularına aktarılabilir ve depolanabilir. Kişisel
            Verilerinizin güvenli bir şekilde ve bu gizlilik politikasına uygun
            şekilde kullanılmasını sağlamak için makul ölçüde gerekli tüm
            adımları atacağız.
          </p>
        </div>
        <div>
          <h3>
            5. Kişisel Verileri korumak için hangi güvenlik önlemleri
            uygulanmaktadır?
          </h3>
          <p>
            Verilerinizin güvenliği ve diğer Kullanıcıların güvenliği bizim için
            çok önemlidir. Kişisel Verilerinizi kayıp ya da herhangi bir
            yasadışı işleme karşı korumak için teknik ve organizasyonel önlemler
            aldık. Aşağıdaki tedbirleri uyguladık: sunucularımızı güvenlik
            duvarları, SSL bağlantıları ve hassas verilerin şifrelenmesi yoluyla
            koruma. Bu liste ayrıntılı değildir.
          </p>
        </div>
        <div>
          <h3>6. Üçüncü şahıslarla ilgili sorumluluk sınırları</h3>
          <p>
            Hizmetimiz üçüncü taraflar tarafından sunulan hizmetler ve ürünler
            ve / veya ortakların, reklamverenlerin ve diğer üçüncü tarafların
            web sitelerine veya hizmetlerine köprüler içerebilir. Tarafların, bu
            üçüncü tarafların içeriği, web siteleri veya hizmetleri üzerinde
            herhangi bir kontrolü veya etkisi yoktur. Üçüncü kişi web
            sitelerinin ve hizmetlerinin kullanımı için farklı gizlilik
            politikaları uygulanabilir. Bu Gizlilik Beyanı, yalnızca taraflar
            tarafından Hizmeti kendi amaçları doğrultusunda kullanmanız yoluyla
            elde edilen Kişisel Verilerle ilgilidir. Taraflar, üçüncü taraf web
            sitelerinin ve hizmetlerin içeriği, uygulamaları veya işletilmesi
            ile ilgili herhangi bir sorumluluk kabul etmez.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogGizlilikPolitikasi;
