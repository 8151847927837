import React, { useState } from "react";
import UstMenu from "../layouts/UstMenu";

const Blog = () => {
  return (
    <div>
      <UstMenu />
      <div>
        <h1 style={{
          textAlign:'center',
        }}>Blog</h1>

      </div>
    </div>
  );
};

export default Blog;
