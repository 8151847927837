import React, { useEffect, useState } from "react";

import logo from "../assets/img/logo.png";
import UstMenu from "../layouts/UstMenu";
import Update from "../assets/svg/Update";
import Delete from "../assets/svg/Delete";

const SorunBildir = () => {
  const [reportProblem, setreportProblem] = useState([]);

  // Component yüklendiğinde veri getirme
  useEffect(() => {
    fetchNews();
  }, []);

  // Veriyi getiren fonksiyon
  const fetchNews = async () => {
    try {
      const response = await fetch("http://localhost:3030/api/issues");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setreportProblem(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <UstMenu />
      <nav className="container">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Başlık</th>
              <th scope="col">Açıklama</th>
              <th scope="col">Mail</th>
              <th scope="col">Güncelle</th>
              <th scope="col">Sil</th>
            </tr>
          </thead>
          <tbody>
            {reportProblem.map((report, index) => (
              <tr key={report.id || index}>
                <th scope="row">{index + 1}</th>
                <td>{report.baslik}</td>
                <td>{report.aciklama}</td>
                <td>{report.email}</td>
                <td>
                  <a href="#">
                    {" "}
                    <Update height={20} width={20} fill={"#0000ff"} />
                  </a>
                </td>
                <td>
                  <a href="#">
                    <Delete height={20} width={20} fill={"#ff0000"} />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </nav>
    </div>
  );
};

export default SorunBildir;
