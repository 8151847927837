import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Anasayfa from './views/Anasayfa';
import HaberEkle from './views/HaberEkle';
import HaberList from './views/HaberList';
import StoryList from './views/StoryList';
import DovizTakip from './views/DovizTakip';
import SorunBildir from './views/SorunBildir';
import Duyur from './views/Duyur';
import StoryEkle from './views/StoryEkle';
import Blog from './views/Blog';
import BlogGizlilikPolitikasi from './views/BlogGizlilikPolitikasi';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route exact path="/" element={<Anasayfa />} />
          <Route exact path="/HaberEkle" element={<HaberEkle />} />
          <Route exact path="/HaberList" element={<HaberList />} />
          <Route exact path="/StoryEkle" element={<StoryEkle />} />
          <Route exact path="/StoryList" element={<StoryList />} />
          <Route exact path="/SorunBildir" element={<SorunBildir />} />
          <Route exact path="/DovizTakip" element={<DovizTakip />} />
          <Route exact path="/Duyur" element={<Duyur />} />
          <Route exact path="/Blog" element={<Blog />} />
          <Route exact path="/blog/gizlilik-politikasi" element={<BlogGizlilikPolitikasi />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;