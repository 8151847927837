import React, { useEffect, useState } from "react";
import logo from "../assets/img/logo.png";
import UstMenu from "../layouts/UstMenu";
import Update from "../assets/svg/Update";
import Delete from "../assets/svg/Delete";

const StoryList = () => {
  const [storiesList, setStoriesList] = useState([]);

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const response = await fetch("http://localhost:3030/api/stories");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setStoriesList(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <UstMenu />
      <nav className="container">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Resim</th>
              <th scope="col">Paylaşıcı</th>
              <th scope="col">Açıklama</th>
              <th scope="col">Güncelle</th>
              <th scope="col">Sil</th>
            </tr>
          </thead>
          <tbody>
            {storiesList.map((story, index) => (
              <tr key={story.id || index}>
                <th scope="row">{index + 1}</th>
                <td>
                  <img
                    src={`http://localhost:3030${story.resimUrl}` || logo}
                    height={60}
                    width={180}
                    alt="story"
                  />
                </td>
                <td>{story.paylasiciAdi}</td>
                <td>{story.aciklama}</td>
                <td>
                  <a href="#">
                    {" "}
                    <Update height={20} width={20} fill={"#0000ff"} />
                  </a>
                </td>
                <td>
                  <a href="#">
                    <Delete height={20} width={20} fill={"#ff0000"} />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </nav>
    </div>
  );
};

export default StoryList;
