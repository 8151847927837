import React, { useState } from "react";
import UstMenu from "../layouts/UstMenu";

const StoryEkle = () => {
  const [resim, setResim] = useState(null);
  const [paylasiciAdi, setPaylasici] = useState("");
  const [aciklama, setAciklama] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("resim", resim); // Bu alanın backend'de 'resim' olarak kullanılmasını doğrulayın
    formData.append("paylasiciAdi", paylasiciAdi); // Bu alanları da doğrulayın
    formData.append("aciklama", aciklama);

    try {
      const response = await fetch("http://localhost:3030/api/stories", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      alert("Story başarıyla eklendi!");
      console.log(result);
    } catch (error) {
      console.error("Error:", error);
      alert("Haber eklenirken bir hata oluştu.");
    }
  };

  return (
    <div>
      <UstMenu />
      <div className="container col-12 col-md-6">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="formFile" className="form-label">
              Story Resim
            </label>
            <input
              className="form-control"
              type="file"
              id="formFile"
              onChange={(e) => setResim(e.target.files[0])}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="paylasici" className="form-label">
              Paylaşıcı
            </label>
            <input
              type="text"
              className="form-control"
              id="paylasici"
              aria-describedby="paylasiciHelp"
              value={paylasiciAdi}
              onChange={(e) => setPaylasici(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="aciklama" className="form-label">
              Açıklama
            </label>
            <input
              type="text"
              className="form-control"
              id="aciklama"
              aria-describedby="aciklamaHelp"
              value={aciklama}
              onChange={(e) => setAciklama(e.target.value)}
            />
          </div>
          <button
            type="submit"
            style={{ backgroundColor: "#E7371F", borderColor: "#E7371F" }}
            className="btn btn-primary"
          >
            Gönder
          </button>
        </form>
      </div>
    </div>
  );
};

export default StoryEkle;
