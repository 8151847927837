import React, { useState } from "react";
import UstMenu from "../layouts/UstMenu";

const Duyur = () => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault(); // Formun default davranışını engelle

    console.log("formData", title, body, imageUrl); // Debugging için formData'yı konsola yazdırın

    try {
      const response = await fetch("http://localhost:3030/api/notify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: title,
          body: body,
          imageUrl: imageUrl,
        }), // JSON.stringify ile bir nesne oluşturulmalı
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      alert("Duyuru Yapıldı!");
      console.log(result);
    } catch (error) {
      console.error("Error:", error);
      alert("Haber eklenirken bir hata oluştu.");
    }
  };

  return (
    <div>
      <UstMenu />
      <div className="container col-12 col-md-6">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="resim" className="form-label">
              Title Resim
            </label>
            <input
              type="text"
              className="form-control"
              id="resim"
              aria-describedby="resimHelp"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="title" className="form-label">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              aria-describedby="resimHelp"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="body" className="form-label">
              Body
            </label>
            <input
              type="text"
              className="form-control"
              id="body"
              aria-describedby="resimHelp"
              value={body}
              onChange={(e) => setBody(e.target.value)}
            />
          </div>
          <button
            type="submit"
            style={{ backgroundColor: "#E7371F", borderColor: "#E7371F" }}
            className="btn btn-primary"
          >
            Gönder
          </button>
        </form>
      </div>
    </div>
  );
};

export default Duyur;
