import React from "react";
import logo from "../assets/img/logo.png";
import UstMenu from "../layouts/UstMenu";

const Anasayfa = () => {
  return (
    <div>
      <h1
        style={{
          color: "#E7371F",
          marginTop: 50,
          fontSize: 45,
          fontWeight:'800',
          textAlign: "center",
        }}
      >
        Doviz Up Web Yakında sizlerle
      </h1>
    </div>
  );
};

export default Anasayfa;
